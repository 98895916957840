<template>
  <TheSidebar></TheSidebar>
  <section id="PageHome" class="col-12 col-md-9 wrapper-text">
    <div class="card mb-3">
      <div class="card-header bg-color-primary-2 text-white text-center">
        پیام مدیریت
      </div>
      <div class="card-body">
        <h1>به وب سایت دانلود فیلم من خوش آمدید.</h1>
        <p>
          در این وب سایت شما می توانید جدید ترین فیلم و سریال های روز جهان را
          مشاهده نمایید. در این پروژه که به صورت آزمایشی است سعی شده است که
          مهارت های برنامه نویسی <strong class="text-danger">Vue</strong> من
          مورد چالش قرار بگیرد.
        </p>
        <div class="alert alert-warning" role="alert">
          لطفا فیلترشکن خود را فعال کنید چون برای برقراری ارتباط با API، فعال
          بودن فیلترشکن ضروری می باشد. حجم، تعداد و کیفیت تصاویر زیاد است و
          تاخیر نمایش بابت سرعت اینترنت در بارگزاری است.
        </div>
        <div class="alert alert-danger mb-0" role="alert">
          برای این وب سایت اصلا مسئله UI & UX و ریسپانسیو و سئو سایت در نظر
          نگرفته شده است. ولی میتوانید PWA سایت را بررسی کنید.
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <button
                class="nav-link active"
                id="nav-moviepopular-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-moviepopular"
                type="button"
                role="tab"
                aria-controls="nav-moviepopular"
                aria-selected="true"
            >
              فیلم های محبوب
            </button>
            <button
                @click.once="getTvPopular"
                class="nav-link"
                id="nav-tvpopular-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-tvpopular"
                type="button"
                role="tab"
                aria-controls="nav-tvpopular"
                aria-selected="false"
            >
              سریال های محبوب
            </button>
            <button
                @click.once="getMovieTopRating"
                class="nav-link"
                id="nav-toprating-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-toprating"
                type="button"
                role="tab"
                aria-controls="nav-toprating"
                aria-selected="false"
            >
              بیشترین امتیاز
            </button>
            <button
                @click.once="getPopularPerson"
                class="nav-link"
                id="nav-popularperson-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-popularperson"
                type="button"
                role="tab"
                aria-controls="nav-popularperson"
                aria-selected="false"
            >
              بازیگران محبوب
            </button>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div
              class="tab-pane fade show active"
              id="nav-moviepopular"
              role="tabpanel"
              aria-labelledby="nav-moviepopular-tab"
          >
            <div
                class="row"
                v-if="this.$store.state.getMovie.status.moviePopularLoading"
            >
              <div
                  class="col-sm-6 col-md-4 col-lg-3"
                  v-for="movie in moviePopular"
                  :key="movie.id"
              >
                <CardViewMovie
                    :movie="movie"
                    :size="imageSizeMovie('w342')"
                ></CardViewMovie>
              </div>
            </div>
            <spinner v-else/>
          </div>
          <div
              class="tab-pane fade"
              id="nav-tvpopular"
              role="tabpanel"
              aria-labelledby="nav-tvpopular-tab"
          >
            <div
                class="row"
                v-if="this.$store.state.getMovie.status.tvPopularPopularLoading"
            >
              <div
                  class="col-sm-6 col-md-4 col-lg-3"
                  v-for="movie in tvPopular"
                  :key="movie.id"
              >
                <CardViewTv
                    :movie="movie"
                    :size="imageSizeMovie('w342')"
                ></CardViewTv>
              </div>
            </div>
            <spinner v-else/>
          </div>
          <div
              class="tab-pane fade"
              id="nav-toprating"
              role="tabpanel"
              aria-labelledby="nav-toprating-tab"
          >
            <div
                class="row"
                v-if="
                this.$store.state.getMovie.status.movieTopRatingPopularLoading
              "
            >
              <div
                  class="col-sm-6 col-md-4 col-lg-3"
                  v-for="movie in movieTopRating"
                  :key="movie.id"
              >
                <CardViewMovie
                    :movie="movie"
                    :size="imageSizeMovie('w342')"
                ></CardViewMovie>
              </div>
            </div>
            <spinner v-else/>
          </div>
          <div
              class="tab-pane fade"
              id="nav-popularperson"
              role="tabpanel"
              aria-labelledby="nav-popularperson-tab"
          >
            <div
                class="row"
                v-if="this.$store.state.getMovie.status.popularPersonLoading"
            >
              <div
                  class="col-sm-6 col-md-4 col-lg-3"
                  v-for="person in popularPerson"
                  :key="person.id"
              >
                <CardViewPerson
                    :person="person"
                    :size="imageSizeMovie('w342')"
                ></CardViewPerson>
              </div>
            </div>
            <Spinner v-else/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {defineAsyncComponent} from 'vue'

export default {
  name: "PageHome",
  components: {
    'CardViewMovie': defineAsyncComponent(() =>
        import('@/components/cardView/cardViewMovie')
    ),
    'CardViewPerson': defineAsyncComponent(() =>
        import('@/components/cardView/cardViewPerson')
    ),
    'Spinner': defineAsyncComponent(() =>
        import('@/components/template/spinner')
    ),
    'CardViewTv': defineAsyncComponent(() =>
        import('@/components/cardView/cardViewTv')
    ),
    'TheSidebar': defineAsyncComponent(() =>
        import('@/components/TheSidebar')
    )
  },
  computed: {
    moviePopular() {
      return this.$store.state.getMovie.moviePopular;
    },
    tvPopular() {
      return this.$store.state.getMovie.tvPopular;
    },
    movieTopRating() {
      return this.$store.state.getMovie.movieTopRating;
    },
    popularPerson() {
      return this.$store.state.getMovie.popularPerson;
    },
  },
  methods: {
    setArticle() {
      const data = JSON.stringify([
        {
          title: "اولین مقاله من",
          slug: "title1",
          description:
              " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.",
          content:
              " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند. ",
        },
        {
          title: "دومین مقاله من",
          slug: "title2",
          description:
              " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.",
          content:
              " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند. ",
        },
        {
          title: "سومین مقاله من",
          slug: "title3",
          description:
              " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.",
          content:
              " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند. ",
        },
        {
          title: "چهارمین مقاله من",
          slug: "title4",
          description:
              " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.",
          content:
              " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند. ",
        },
        {
          title: "پنجمین مقاله من",
          slug: "title5",
          description:
              " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.",
          content:
              " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند. ",
        },
        {
          title: "ششمین مقاله من",
          slug: "title6",
          description:
              " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.",
          content:
              " در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند.  در این وب سایت به دنبال این هستم کسانی که به دنیای طراحی وب علاقه مند هستند به راحتی بتوانند مسیر یک فرانت اند کار (Front-End) ر به خوبی یاد بگیرند و به صورت عملی و تخصصی یک برنامه نویس موفق شوند. ",
        },
      ]);
      localStorage.setItem("articles", data);
    },
    getMoviePopular() {
      let page = 1;
      let query = `movie/popular?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}&page=${page}`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setMoviePopular",
      });
    },
    getTvPopular() {
      let page = 1;
      let query = `tv/popular?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}&page=${page}`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setTvPopular",
      });
    },
    getMovieTopRating() {
      let page = 1;
      let query = `movie/top_rated?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}&page=${page}`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setMovieTopRating",
      });
    },
    getPopularPerson() {
      let page = 1;
      let query = `person/popular?api_key=${this.$store.state.getMovie.api_key}&language=${this.$store.state.getMovie.lang}&page=${page}`;
      this.$store.dispatch("getMovie/fetchData", {
        query: query,
        setName: "setPopularPerson",
      });
    },
  },
  mounted() {
    this.getMoviePopular();
    this.setArticle();
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 575px) {
  .nav-tabs .nav-link {
    padding: 5px;
    font-size: 12px;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .nav-tabs .nav-link {
    padding: 5px;
  }
}
</style>
